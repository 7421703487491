<template>
  <div class="container free">
    <div class="tips co_333 text-center">
      <p class="f-w700">日语资料包免费领取</p>
      <p>涵盖90%日语学习内容</p>
    </div>
    <div class="b-r12 bg-fff content">
      <p class="title co_333 text-center">扫码下载app 制订计划领取资料包</p>
      <div
        class="img d-flex justify-content-space-between align-items-center"
        @click="download"
      >
        <img src="@/assets/left.png" alt="" />
        <img :src="imgurl" alt="" />
        <img src="@/assets/right.png" alt="" />
      </div>
      <div class="step">
        <div class="step-icon d-flex align-items-center justify-content-center">
          <img src="@/assets/Group 427318266.png" alt="" />
          <img src="@/assets/Group 427318269.png" alt="" class="p-19" />
          <img src="@/assets/Group 427318267.png" alt="" />
          <img src="@/assets/Group 427318269.png" alt="" class="p-22" />
          <img src="@/assets/Group 427318268.png" alt="" />
        </div>
        <div class="step-text d-flex align-items-center justify-content-center">
          <div class="text-center">
            <p class="f-w700 co_333">点击</p>
            <p class="co_666">点击进入</p>
          </div>
          <div class="text-center">
            <p class="f-w700 co_333">进入应用商店</p>
            <p class="co_666">点击下载app</p>
          </div>
          <div class="text-center">
            <p class="f-w700 co_333">下载APP</p>
            <p class="co_666">领资料上课</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="b-r12 bg-fff img-item">
      <div class="img-box">
        <img src="@/assets/shareBg.png" alt="" />
      </div> -->
    <!-- <div class="item" v-for="(item, idx) in 6" :key="idx">
        <img src="@/assets/img1.png" alt="" />
        <p class="co_333 text-center">零基础视频</p>
      </div> -->
    <!-- </div> -->
    <!-- <downBtn
      :topShow="true"
      :isWx="isWx"
      @down="download"
      @wechatShow="wechatShow"
    ></downBtn> -->
  </div>
</template>

<script>
import downBtn from "@/components/downBtn";
import mixin from "@/utils/mixin";
import { getTeacherQrCode } from '@/api/teacher.js'
export default {
  name: "",
  data() {
    return {
      imgurl:""
    };
    
  },
  mixins: [mixin],
  components: {
    downBtn,
  },
  created() {
    this.getTeacherQrCode()
  },

  methods: {
   async getTeacherQrCode() {
    const data = await getTeacherQrCode({application : 2 , type : 1})
    console.log(data);
    this.imgurl = data.data
    }
  },
};
</script>

<style lang="scss" scoped>
.free {
  min-height: 100vh;
  overflow: hidden;
  background: url("~@/assets/image 223.png") no-repeat;
  background-position: top right;
  background-size: 162px 162px;
  background-color: #fef1ed;
  .tips {
    font-size: 18px;
    padding: 60px 0 30px;
    p {
      line-height: 25px;
    }
    .f-w700 {
      margin-bottom: 8px;
    }
  }
  .content {
    margin: 0 16px;
    padding: 20px;
    .title {
      font-size: 18px;
      line-height: 19px;
      margin-bottom: 24px;
    }
    .img {
      margin-bottom: 30px;
      img {
        &:nth-child(1) {
          width: 80px;
        }
        &:nth-child(2) {
          width: 85px;
          height: 85px;
        }
        &:nth-child(3) {
          width: 85px;
        }
      }
    }
    .step {
      .p-19 {
        padding: 0 19px;
      }
      .p-22 {
        padding-left: 22px;
        padding-right: 16px;
      }
      &-icon {
        margin-bottom: 12px;
        img {
          &:nth-child(odd) {
            width: 36px;
          }
          &:nth-child(even) {
            width: 23px;
          }
        }
      }
      &-text {
        font-size: 12px;
        .text-center {
          p {
            line-height: 17px;
          }
          &:nth-child(1) {
            margin-left: 5px;
          }
          &:nth-child(2) {
            margin-left: 38px;
            margin-right: 31px;
          }
        }
      }
    }
  }
  .img-item {
    margin: 20px 16px;
    padding: 16px 15px;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px 43px;
    .img-box {
      img {
        height: 162px;
        // width: 314px;
      }
    }
    .item {
      img {
        width: 75px;
      }
      p {
        margin-top: 10px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
</style>
