import api from '@/utils/request'

// 教师风采详情
// /teacherStyle/getTeacherStyleById
export function getTeacherStyleById(params) {
  return api({
    method: 'get',
    url: '/teacherStyle/getTeacherStyleById',
    params,
  })
}
export function getTeacherStyleVOList(params) {
  return api({
    method: 'get',
    url: '/teacherStyle/getTeacherStyleVOList',
    params,
  })
}
export function getTeacherQrCode(params) {
  return api({
    method: 'get',
    url: '/teacherStyle/getTeacherQrCode',
    params,
  })
}

export const InterestData = (params) => api({
  method: 'get',
  url: '/interestCourses/getInterestCourses',
  params,
})

export const DownloadAddress = (params) => api({
  method: 'get',
  url: '/app/version/getVersion',
  params,
})

export const Syllable = (params) => api({
  method: 'get',
  url: '/japanese/syllable/dictionaries',
  params,
})


export const Oneoaching = (params) => api({
  method: 'get',
  url: '/coaching/getCoachings',
  params,
})