import axios from 'axios'
import { Toast } from 'vant'

const service = axios.create({
  // baseURL: process.env.VUE_APP_API_URL,
  baseURL: "https://japanese-server.yangcongjiaoyu.com",  
  // baseURL: "http://server-japanese-test.yangcongjiaoyu.com/",
  // baseURL: "http://japanese-h5-test.jixedu.cn",
  timeout: 5000, // request timeout
})
// request interceptor
service.interceptors.request.use(
  (config) => {
    // config.headers.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXNzd29yZCI6IkUxMEFEQzM5NDlCQTU5QUJCRTU2RTA1N0YyMEY4ODNFIiwiZXhwIjoxNjQ3MjcwODAyLCJhY2NvdW50IjoienhsIn0.cafoxalKagZsOUtlhkxIhP3gtGL5crmFWT0JKjotHQc"
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
      message: '加载中...',
    })
    return config
  },
  (error) => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data
    Toast.clear()
    if (res.code != 'SUCCESS') {
      let message = res.message
      if (res.message && res.message.length > 20) {
        message = '系统错误'
      }
      Toast.fail(message)
      return Promise.reject(res)
    }
    return res
  },
  (error) => {
    console.log('err' + error) // for debug
    Toast.clear()
    Toast.fail(error.message || '哎呀~客官请稍等片刻~')
    return Promise.reject(error)
  }
)

export default service
